<template>
  <div class="relevance-word-cloud d-flex justify-center justify-md-end">
    <!-- only show in case there's something to show -->
    <div
      v-if="tags.length"
      class="my-4"
    >
      <v-card
        class="border-radius-1em box-shadow-soft"
        :width="`${computedWidth + 32}px`"
      >
        <v-card-text>
          <v-progress-linear
            :active="isLoading"
            :indeterminate="true"
            absolute
            top
            color="primary"
          ></v-progress-linear>

          <vue-word-cloud
            :style="`
              height: 250px;
              width: ${computedWidth};
            `"
            :words="words"
            :color="colors"
            :rotation="rotation"
            :spacing="0.2"
            :font-size-ratio="6"
            font-family="Times, Serif"
            font-weight="bold"
          />

          <div class="text-body-1 mt-3 mb-2">
            Search Priority
          </div>

          <div class="d-flex align-center">
            <span>Relevance</span>

            <v-slider
              v-model="form.slider"
              thumb-label
              color="primary"
              :min="0.05"
              :max="1"
              :step="0.05"
              ticks="always"
              hide-details
              dense
              @change="handleSliderChange"
            ></v-slider>

            <span>Account Size</span>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// Import node package
import Chance from 'chance'
// Import components
import VueWordCloud from 'vuewordcloud'
// Import helper generator
import wordCloudColors from '@/json/wordCloudColors.js'

/**
 * Generate the default state values for this component
 *
 * @param {Object} param
 * @returns {void}
 */
const defaultState = () => ({
  tags: [],
  words: [],

  colorsList: wordCloudColors(),

  isLoading: false,

  form: {
    slider: 0.5
  }
})

// Export the SFC
export default {
  // Name of the component
  name: "WordCloud",

  // Register the components
  components: {
    VueWordCloud
  },

  // Accept incoming data from parent
  props: {
    platform: String
  },

  // Define local data variables
  data: () => defaultState(),

  // Define computable readonly variables
  computed: {
    /**
     * Get the width for the container
     *
     * @returns {Number}
     */
    computedWidth() {
      return this.isDesktopDevice ? 450 : 300
    },

    /**
     * Generate random rotation value for the words
     *
     * @returns {Chance}
     */
    rotation() {
      return (word) => {
        return (new Chance(word[0])).pickone([0])
      }
    },

    /**
     * Return a random color for the word
     *
     * @returns {String}
     */
    colors() {
      return () => {
        return (new Chance()).pickone(this.colorsList)
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Generate the word cloud using an array of tags
     *
     * @returns {void}
     */
    buildWordCloud() {
      // cancel pending call
      if (this._timerId) {
        clearTimeout(this._timerId)
      }

      // delay new call 500ms
      this._timerId = setTimeout(async () => {
        this.isLoading = true

        // for each of the tags, go and search relevant tags for them
        for (const tag of this.tags) {
          // reset the words list
          this.words = []

          try {
            const queryParams = new window.URLSearchParams({
              q: tag,
              platform: this.platform
            })

            const response = await axios({
              url: "/api/filters/relevant-tags?" + queryParams,
            })

            // the API responds like
            this.words = [
              ...this.words,
              ...response.data
                .slice(0, 20)
                .map((item) => {
                  return [item.tag, Math.round(item.freq * item.distance * Math.random() * 100)]
                })
            ]
          } catch (error) {
            // log using the helper function
            logger({ type: "Network Error", error })
          } finally {
            this.isLoading = false
          }
        }
      }, 500)
    },

    /**
     * Update the filter value based on the strength of filter
     *
     * @returns {void}
     */
    handleSliderChange() {
      this.$store.dispatch("influencerDiscovery/replaceFilter", {
        type: "relevanceWeight",
        isHidden: true,
        data: {
          color: "purple lighten-5",
          icon: "tag",
          iconColor: "purple",
          text: `Relevance Weight ${this.form.slider}`,
          inputs: {
            value: this.form.slider
          }
        }
      })
    },

    /**
     * Handle teh event when the search input value changes
     *
     * @param {Event} e
     * @returns {void}
     */
    handleRelevanceSearchUpdate(e) {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery") return

      // now that it is the one we want to see, continue
      this.tags = e.detail.items.map((item) => item.tag)

      // if there are items in tags
      if (this.tags.length) {
        // and fetch the data to show the word-cloud
        this.buildWordCloud()
      }
      // otherwise reset the words
      else {
        this.words = []
      }
    },

    /**
     * Handle the event when one of the filters have been removed
     *
     * @param {Event} e
     * @returns {void}
     */
    handleRemoveFilter(e) {
      // if it's not the one we want, don't execute the code
      if (
        e.detail.module !== "influencerDiscovery" ||
        e.detail.item.type !== "lookAlike" ||
        e.detail.item.data.kind !== "influencer"
      ) return

      // now that it is the one we want to see, continue
      // find the item and remove it
      const _text1 = `@${e.detail.item.data.inputs.username || e.detail.item.data.inputs.custom_name || e.detail.item.data.inputs.user_id}`
      const _text2 = `@${e.detail.item.data.inputs.user_id || e.detail.item.data.inputs.custom_name || e.detail.item.data.inputs.username}`

      const text = (this.platform === "youtube" ? _text2 : _text1)
      const index = this.tags.findIndex((search) => search === text)

      if (index !== -1) {
        // remove the item
        this.tags.splice(index, 1)

        // rebuild the cloud
        this.buildWordCloud()
      }
    },

    /**
     * Handle when a filter has been added
     *
     * @param {Event} e
     * @returns {void}
     */
    handleAddFilter(e) {
      // if it's not the one we want, don't execute the code
      if (
        e.detail.module !== "influencerDiscovery" ||
        e.detail.item.type !== "lookAlike" ||
        e.detail.item.data.kind !== "influencer"
      ) return

      // now that it is the one we want to see, continue
      const _text1 = `@${e.detail.item.data.inputs.username || e.detail.item.data.inputs.custom_name || e.detail.item.data.inputs.user_id}`
      const _text2 = `@${e.detail.item.data.inputs.user_id || e.detail.item.data.inputs.custom_name || e.detail.item.data.inputs.username}`

      const text = (this.platform === "youtube" ? _text2 : _text1)
      const index = this.tags.findIndex((search) => search === text)

      // if it does not exist
      if (index === -1) {
        // remove the item
        this.tags.push(text)

        // rebuild the cloud
        this.buildWordCloud()
      }
    },

    /**
     * Handle when the platform changes
     *
     * @param {Event} e
     * @returns {void}
     */
    handlePlatformChange(e) {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery") return

      // reset the state
      Object.assign(this.$data, defaultState())
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // listen for the inputs from relevance-search component
    window.addEventListener("relevanceSearchUpdate", this.handleRelevanceSearchUpdate)

    // subscribe to events about lookalikes
    window.addEventListener("removeFilter", this.handleRemoveFilter)

    // subscribe to window event when the chip is closed
    window.addEventListener("addFilter", this.handleAddFilter)

    // subscribe to window event when the platform has been changed
    window.addEventListener("platformChange", this.handlePlatformChange)
  },

  /**
   * As soon as the component is about to be destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // stop listening for the inputs from relevance-search component
    window.removeEventListener("relevanceSearchUpdate", this.handleRelevanceSearchUpdate)

    // unsubscribe to events about lookalikes
    window.removeEventListener("removeFilter", this.handleRemoveFilter)

    // unsubscribe to window event when the chip is closed
    window.removeEventListener("addFilter", this.handleAddFilter)

    // unsubscribe to window event when the platform has been changed
    window.removeEventListener("platformChange", this.handlePlatformChange)
  }
}
</script>

<style lang="stylus">
.relevance-word-cloud
  .cloud-container
    width 100%
    height 250px
</style>
